<template>
  <div class="alipay">
    <img class="gold" src="@/assets/img/alipay/gold.png" />
    <p>权益已发放</p>
    <div class="txt">通过<span>易点小程序</span>兑换</div>
    <div class="box">
      <img src="@/assets/img/alipay/logo.png" />
      <p>易点小程序</p>
      <div class="alipayTxt">支付宝小程序</div>
      <div class="boxBtn" @click="openAlipay()">
        <img src="@/assets/img/alipay/btn1.png" />
        <span>打开支付宝小程序</span>
        <img class="btn2" src="@/assets/img/alipay/btn2.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {
    openAlipay() {
      const { code: redeCodeNo } = this.$route.params
      const query = `${encodeURIComponent(`redeCodeNo=${redeCodeNo}`)}`
      window.location.href =
        'https://ds.alipay.com/?scheme=' +
        encodeURIComponent(
          `alipays://platformapi/startapp?appId=2021004146680588&page=pages/login/index&query=${query}`
        )
    }
  }
}
</script>
<style lang="less" scoped>
.alipay {
  min-height: 100vh;
  background: url('~@/assets/img/alipay/bg.png') center center no-repeat;
  background-size: cover;
  font-size: 28px;
  color: #000001;
  text-align: center;
  .gold {
    width: 129px;
    display: block;
    margin: auto;
    padding: 116px 0 40px;
  }
  .txt {
    font-weight: 600;
    font-size: 36px;
    padding: 20px 0 134px;
    span {
      color: #f93d2d;
    }
  }
  .box {
    background: #ffffff;
    border-radius: 20px;
    width: 640px;
    margin: auto;
    box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.5);
    font-size: 32px;
    color: #000000;
    padding: 112px 0 106px;
    & > img {
      width: 92px;
      display: block;
      margin: 0px auto 55px;
    }
    .alipayTxt {
      width: 165px;
      height: 44px;
      line-height: 44px;
      margin: 22px auto 60px;
      font-size: 22px;
      color: #fe4e40;
      border-radius: 22px;
      border: 1px solid #fe4e40;
    }
    .boxBtn {
      width: 580px;
      height: 90px;
      background: linear-gradient(0deg, #ff443b, #f99368);
      border-radius: 45px;
      border: 4px solid #feefd0;
      font-size: 32px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 0px;
      & > img {
        width: 36px;
        margin-right: 12px;
      }
      .btn2 {
        width: 12px;
        margin-left: 12px;
      }
    }
  }
}
</style>
